import React, { useContext, useEffect } from 'react';
import { ApiContext } from '../../api/api';
import { useAuth0 } from '@auth0/auth0-react';
import { useImmer } from 'use-immer';
import { useRouter } from 'next/router';
import { isNullOrUndefined } from '@utils/utils';
import { Draft } from 'immer';
import { CargoTemplateWithoutIdDTO, LovWithoutIdDTO } from '@api/logsteo-api.v2';

interface SeletedCompany {
  id: string;
  companyType: string;
  composedId: string;
}

interface AdminSettingsType {
  selectedCompany?: SeletedCompany;
}

interface ApplicationContextType {
  settings: { [key: string]: any };
  adminSettings: AdminSettingsType;
  cargoTemplates: CargoTemplateWithoutIdDTO[];
  currencies: LovWithoutIdDTO[];
}

export const ApplicationContext = React.createContext<ApplicationContextType>(undefined);

export const ApplicationSettings: React.FC = ({ children }) => {
  const { getCompanySettings, listCompanyUsers, lovCargoItemType, lovCurrency } = useContext(ApiContext);
  const { isAuthenticated } = useAuth0();
  const [settings, setSettings] = useImmer<ApplicationContextType>({
    settings: [],
    adminSettings: {},
    cargoTemplates: [],
    currencies: [],
  });
  const router = useRouter();

  const createContext = (value: string): SeletedCompany => {
    if (isNullOrUndefined(value)) return null;
    const regexp = /([\w]+)-(.+)/;
    const result = value.match(regexp);
    const companyType = result[1];
    const id = result[2];

    if (!isNullOrUndefined(id)) return { id, companyType, composedId: value };
  };

  useEffect(() => {
    if (isAuthenticated) {
      getCompanySettings({ identifier: 'LOCATION_DISPLAY_PATTERN' }, (d) => {
        setSettings((draft) => {
          draft.settings = { ...draft.settings, LOCATION_DISPLAY_PATTERN: d.payload };
        });
      });
    }

    setSettings((draft) => {
      const value = router.query['adminCompanyId'] as string;
      const context = createContext(value);
      draft.adminSettings.selectedCompany = isNullOrUndefined(context) ? null : context;
    });
  }, [router.query]);

  useEffect(() => {
    if (isAuthenticated) {
      lovCargoItemType((data) => {
        setSettings((draft) => {
          draft.cargoTemplates = data;
        });
      });
      lovCurrency((data) => {
        setSettings((draft) => {
          draft.currencies = data;
        });
      });
    }
  }, [isAuthenticated]);

  return <ApplicationContext.Provider value={settings}>{children}</ApplicationContext.Provider>;
};
